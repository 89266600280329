// Modules
import { useState } from "react";
// Components
import { Header } from "../../Common/Header/Header";
import { Image } from "../../Common/Image/Image";
import { Footer } from "../../Common/Footer/Footer";
import { ImageList } from "../../Common/ImageList/ImageList";
import { About } from "../../Content/About/About";
import UserInfo from "../../Security/UserInfo/UserInfo";
import { MobileNavigator } from "../../Common/MobileNavigator/MobileNavigator";
// Styles
import Styles from "./Styles/Home.module.css";
// Images
//* landing
import back1 from "../../../assets/Images/back1.png";
import back2 from "../../../assets/Images/back2.png";
import j1 from "../../../assets/Images/landing/l (10).jpg";
import j2 from "../../../assets/Images/landing/l (9).jpg";
import j3 from "../../../assets/Images/landing/l (17).jpg";
import j4 from "../../../assets/Images/landing/l (13).jpg";
import j5 from "../../../assets/Images/landing/l (2).jpg";
import j6 from "../../../assets/Images/landing/l (15).jpg";
import j7 from "../../../assets/Images/landing/l (16).jpg";
import j8 from "../../../assets/Images/landing/l (6).jpg";
import j9 from "../../../assets/Images/landing/l (18).jpg";
import j10 from "../../../assets/Images/landing/l (14).jpg";
import j11 from "../../../assets/Images/landing/l (3).jpg";
import j12 from "../../../assets/Images/landing/l (19).jpg";
import j13 from "../../../assets/Images/landing/l (5).jpg";
import j14 from "../../../assets/Images/landing/l (4).jpg";
import j15 from "../../../assets/Images/landing/l (8).jpg";
//* single
// girl
import sg111 from "../../../assets/Images/single/girl/11 (1).jpg";
import sg112 from "../../../assets/Images/single/girl/11 (10).jpg";
import sg113 from "../../../assets/Images/single/girl/11 (11).jpg";
import sg114 from "../../../assets/Images/single/girl/11 (12).jpg";
import sg115 from "../../../assets/Images/single/girl/11 (13).jpg";
import sg116 from "../../../assets/Images/single/girl/11 (14).jpg";
import sg117 from "../../../assets/Images/single/girl/11 (15).jpg";
import sg118 from "../../../assets/Images/single/girl/11 (16).jpg";
import sg119 from "../../../assets/Images/single/girl/11 (17).jpg";
import sg1110 from "../../../assets/Images/single/girl/11 (18).jpg";
import sg1111 from "../../../assets/Images/single/girl/11 (19).jpg";
import sg1112 from "../../../assets/Images/single/girl/11 (2).jpg";
import sg1113 from "../../../assets/Images/single/girl/11 (20).jpg";
import sg1114 from "../../../assets/Images/single/girl/11 (21).jpg";
import sg1115 from "../../../assets/Images/single/girl/11 (22).jpg";
import sg1116 from "../../../assets/Images/single/girl/11 (23).jpg";
import sg1117 from "../../../assets/Images/single/girl/11 (24).jpg";
import sg1118 from "../../../assets/Images/single/girl/11 (25).jpg";
import sg1119 from "../../../assets/Images/single/girl/11 (26).jpg";
import sg1120 from "../../../assets/Images/single/girl/11 (27).jpg";
import sg1121 from "../../../assets/Images/single/girl/11 (28).jpg";
import sg1122 from "../../../assets/Images/single/girl/11 (29).jpg";
import sg1123 from "../../../assets/Images/single/girl/11 (30).jpg";
import sg1124 from "../../../assets/Images/single/girl/11 (31).jpg";
import sg1125 from "../../../assets/Images/single/girl/11 (32).jpg";
import sg1126 from "../../../assets/Images/single/girl/11 (33).jpg";
import sg1127 from "../../../assets/Images/single/girl/11 (34).jpg";
// boy
import sb121 from "../../../assets/Images/single/boy/12 (1).jpg";
import sb122 from "../../../assets/Images/single/boy/12 (10).jpg";
import sb123 from "../../../assets/Images/single/boy/12 (11).jpg";
import sb124 from "../../../assets/Images/single/boy/12 (4).jpg";
import sb125 from "../../../assets/Images/single/boy/12 (5).jpg";
import sb126 from "../../../assets/Images/single/boy/12 (6).jpg";
import sb127 from "../../../assets/Images/single/boy/12 (7).jpg";
import sb128 from "../../../assets/Images/single/boy/12 (8).jpg";
import sb129 from "../../../assets/Images/single/boy/12 (9).jpg";
//* double
// girl
import dg211 from "../../../assets/Images/double/girls/21 (1).jpg";
import dg212 from "../../../assets/Images/double/girls/21 (10).jpg";
import dg213 from "../../../assets/Images/double/girls/21 (2).jpg";
import dg214 from "../../../assets/Images/double/girls/21 (3).jpg";
import dg215 from "../../../assets/Images/double/girls/21 (4).jpg";
import dg216 from "../../../assets/Images/double/girls/21 (5).jpg";
import dg217 from "../../../assets/Images/double/girls/21 (6).jpg";
import dg218 from "../../../assets/Images/double/girls/21 (7).jpg";
import dg219 from "../../../assets/Images/double/girls/21 (8).jpg";
import dg2110 from "../../../assets/Images/double/girls/21 (9).jpg";
// child
import dc221 from "../../../assets/Images/double/child/23 (1).jpg";
import dc222 from "../../../assets/Images/double/child/23 (2).jpg";
import dc223 from "../../../assets/Images/double/child/23 (3).jpg";
import dc224 from "../../../assets/Images/double/child/23 (4).jpg";
import dc225 from "../../../assets/Images/double/child/23 (5).jpg";
import dc226 from "../../../assets/Images/double/child/23 (6).jpg";
// love
import dl231 from "../../../assets/Images/double/love/24 (1).jpg";
import dl232 from "../../../assets/Images/double/love/24 (2).jpg";
import dl233 from "../../../assets/Images/double/love/24 (3).jpg";
import dl234 from "../../../assets/Images/double/love/24 (4).jpg";
import dl235 from "../../../assets/Images/double/love/24 (5).jpg";
import dl236 from "../../../assets/Images/double/love/24 (6).jpg";
import dl237 from "../../../assets/Images/double/love/24 (7).jpg";
import dl238 from "../../../assets/Images/double/love/24 (8).jpg";
import dl239 from "../../../assets/Images/double/love/24 (9).jpg";
import dl2310 from "../../../assets/Images/double/love/24 (10).jpg";
import dl2311 from "../../../assets/Images/double/love/24 (11).jpg";
import dl2312 from "../../../assets/Images/double/love/24 (12).jpg";
import dl2313 from "../../../assets/Images/double/love/24 (13).jpg";
import dl2314 from "../../../assets/Images/double/love/24 (14).jpg";
import dl2315 from "../../../assets/Images/double/love/24 (15).jpg";
import dl2316 from "../../../assets/Images/double/love/24 (16).jpg";
import dl2317 from "../../../assets/Images/double/love/24 (17).jpg";
import dl2318 from "../../../assets/Images/double/love/24 (18).jpg";
import dl2319 from "../../../assets/Images/double/love/24 (19).jpg";
import dl2320 from "../../../assets/Images/double/love/24 (20).jpg";
import dl2321 from "../../../assets/Images/double/love/24 (21).jpg";
import dl2322 from "../../../assets/Images/double/love/24 (22).jpg";
import dl2323 from "../../../assets/Images/double/love/24 (23).jpg";
//* nature
import n1 from "../../../assets/Images/nature/n (1).jpg";
import n2 from "../../../assets/Images/nature/n (2).jpg";
import n3 from "../../../assets/Images/nature/n (3).jpg";
import n4 from "../../../assets/Images/nature/n (4).jpg";
import n5 from "../../../assets/Images/nature/n (5).jpg";
import n6 from "../../../assets/Images/nature/n (6).jpg";
import n7 from "../../../assets/Images/nature/n (7).jpg";
import n8 from "../../../assets/Images/nature/n (8).jpg";
import n9 from "../../../assets/Images/nature/n (9).jpg";
import n10 from "../../../assets/Images/nature/n (10).jpg";
import n11 from "../../../assets/Images/nature/n (11).jpg";
import n12 from "../../../assets/Images/nature/n (12).jpg";
import n13 from "../../../assets/Images/nature/n (13).jpg";
import n14 from "../../../assets/Images/nature/n (14).jpg";
import n15 from "../../../assets/Images/nature/n (15).jpg";

export const Home = () => {
  const images = [
    // line
    {
      animationDuration: 1,
      src: j1,
      positionLeft: -6.5,
      positionTop: 12,
    },
    {
      animationDuration: 2,
      src: j2,
      positionLeft: 2.65,
      positionTop: 2.8,
    },
    {
      animationDuration: 3,
      src: j3,
      positionLeft: 11.8,
      positionTop: 12,
    },
    {
      animationDuration: 4,
      src: j4,
      positionLeft: 20.95,
      positionTop: 2.8,
    },
    {
      animationDuration: 5,
      src: j5,
      positionLeft: 30.1,
      positionTop: 12,
    },
    {
      animationDuration: 6,
      src: j6,
      positionLeft: 39.25,
      positionTop: 2.8,
    },
    // squar
    {
      animationDuration: 7,
      src: j7,
      positionLeft: 48.4,
      positionTop: 12,
    },
    {
      animationDuration: 8,
      src: j8,
      positionLeft: 57.55,
      positionTop: 2.8,
    },
    {
      animationDuration: 9,
      src: j9,
      positionLeft: 57.55,
      positionTop: 21.2,
    },
    {
      animationDuration: 10,
      src: j10,
      positionLeft: 66.7,
      positionTop: -6.4,
    },
    {
      animationDuration: 11,
      src: j11,
      positionLeft: 66.7,
      positionTop: 12,
    },
    {
      animationDuration: 12,
      src: j12,
      positionLeft: 66.7,
      positionTop: 30.4,
    },
    {
      animationDuration: 13,
      src: j13,
      positionLeft: 75.85,
      positionTop: 2.8,
    },
    {
      animationDuration: 14,
      src: j14,
      positionLeft: 75.85,
      positionTop: 21.2,
    },
    {
      animationDuration: 12,
      src: j15,
      positionLeft: 85,
      positionTop: 12,
    },
    // top right image
    {
      animationDuration: 5,
      src: back1,
      positionLeft: 79.5,
      positionTop: -15.25,
      triangle: true,
    },
    // bottom right image
    {
      animationDuration: 5,
      src: back2,
      positionLeft: 79.5,
      positionTop: 15.4,
      triangle: true,
    },
  ];

  const imagesList = [
    { type: "11", src: sg111, _id: 1 },
    { type: "11", src: sg112, _id: 2 },
    { type: "11", src: sg113, _id: 3 },
    { type: "11", src: sg114, _id: 4 },
    { type: "11", src: sg115, _id: 5 },
    { type: "11", src: sg116, _id: 6 },
    { type: "11", src: sg117, _id: 7 },
    { type: "11", src: sg118, _id: 8 },
    { type: "11", src: sg119, _id: 9 },
    { type: "11", src: sg1110, _id: 10 },
    { type: "11", src: sg1111, _id: 11 },
    { type: "11", src: sg1112, _id: 12 },
    { type: "11", src: sg1113, _id: 13 },
    { type: "11", src: sg1114, _id: 14 },
    { type: "11", src: sg1115, _id: 15 },
    { type: "11", src: sg1116, _id: 16 },
    { type: "11", src: sg1117, _id: 17 },
    { type: "11", src: sg1118, _id: 18 },
    { type: "11", src: sg1119, _id: 19 },
    { type: "11", src: sg1120, _id: 20 },
    { type: "11", src: sg1121, _id: 21 },
    { type: "11", src: sg1122, _id: 22 },
    { type: "11", src: sg1123, _id: 23 },
    { type: "11", src: sg1124, _id: 24 },
    { type: "11", src: sg1125, _id: 25 },
    { type: "11", src: sg1126, _id: 26 },
    { type: "11", src: sg1127, _id: 27 },
    { type: "12", src: sb121, _id: 28 },
    { type: "12", src: sb122, _id: 29 },
    { type: "12", src: sb123, _id: 30 },
    { type: "12", src: sb124, _id: 31 },
    { type: "12", src: sb125, _id: 32 },
    { type: "12", src: sb126, _id: 33 },
    { type: "12", src: sb127, _id: 34 },
    { type: "12", src: sb128, _id: 35 },
    { type: "12", src: sb129, _id: 36 },
    { type: "21", src: dg211, _id: 37 },
    { type: "21", src: dg212, _id: 38 },
    { type: "21", src: dg213, _id: 39 },
    { type: "21", src: dg214, _id: 40 },
    { type: "21", src: dg215, _id: 41 },
    { type: "21", src: dg216, _id: 42 },
    { type: "21", src: dg217, _id: 43 },
    { type: "21", src: dg218, _id: 44 },
    { type: "21", src: dg219, _id: 45 },
    { type: "21", src: dg2110, _id: 46 },
    { type: "22", src: dc221, _id: 47 },
    { type: "22", src: dc222, _id: 48 },
    { type: "22", src: dc223, _id: 49 },
    { type: "22", src: dc224, _id: 50 },
    { type: "22", src: dc225, _id: 51 },
    { type: "22", src: dc226, _id: 52 },
    { type: "23", src: dl231, _id: 53 },
    { type: "23", src: dl232, _id: 54 },
    { type: "23", src: dl233, _id: 55 },
    { type: "23", src: dl234, _id: 56 },
    { type: "23", src: dl235, _id: 57 },
    { type: "23", src: dl236, _id: 58 },
    { type: "23", src: dl237, _id: 59 },
    { type: "23", src: dl238, _id: 60 },
    { type: "23", src: dl239, _id: 61 },
    { type: "23", src: dl2310, _id: 62 },
    { type: "23", src: dl2311, _id: 63 },
    { type: "23", src: dl2312, _id: 64 },
    { type: "23", src: dl2313, _id: 65 },
    { type: "23", src: dl2314, _id: 66 },
    { type: "23", src: dl2315, _id: 67 },
    { type: "23", src: dl2316, _id: 68 },
    { type: "23", src: dl2317, _id: 69 },
    { type: "23", src: dl2318, _id: 70 },
    { type: "23", src: dl2319, _id: 71 },
    { type: "23", src: dl2320, _id: 72 },
    { type: "23", src: dl2321, _id: 73 },
    { type: "23", src: dl2322, _id: 74 },
    { type: "23", src: dl2323, _id: 75 },
    { type: "n", src: n1, _id: 76 },
    { type: "n", src: n2, _id: 77 },
    { type: "n", src: n3, _id: 78 },
    { type: "n", src: n4, _id: 79 },
    { type: "n", src: n5, _id: 80 },
    { type: "n", src: n6, _id: 81 },
    { type: "n", src: n7, _id: 82 },
    { type: "n", src: n8, _id: 83 },
    { type: "n", src: n9, _id: 84 },
    { type: "n", src: n10, _id: 85 },
    { type: "n", src: n11, _id: 86 },
    { type: "n", src: n12, _id: 87 },
    { type: "n", src: n13, _id: 88 },
    { type: "n", src: n14, _id: 89 },
    { type: "n", src: n15, _id: 90 },
  ];

  const [selectedPage, setSelectedPage] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const changeShowMenuState = () => {
    setShowMenu((prev) => !prev);
  };

  const changePage = (page, type) => {
    if (page) {
      setSelectedPage(null);
      setShowMenu(false);
      const data = { key: page, type };
      if (selectedPage?.key !== page && selectedPage !== null) {
        const timeout = setTimeout(() => {
          setSelectedPage(data);
        }, 400);
        return () => clearTimeout(timeout);
      } else {
        setSelectedPage(data);
      }
    } else {
      setSelectedPage(null);
      setShowMenu(false);
    }
  };

  return (
    <div className={Styles.container}>
      <UserInfo />
      {/* mobile navigator */}
      <MobileNavigator
        changePage={changePage}
        showMenu={showMenu}
        changeShowMenuState={changeShowMenuState}
      />
      {/* Header */}
      <Header
        changePage={changePage}
        showMenu={showMenu}
        changeShowMenuState={changeShowMenuState}
      />
      {/* Images */}
      <div className={Styles.imagesContainer}>
        {images.map((image, index) => (
          <Image
            animationDuration={image.animationDuration}
            positionLeft={image.positionLeft}
            positionTop={image.positionTop}
            src={image.src}
            triangle={image.triangle}
            key={index}
          />
        ))}
      </div>
      <Footer />
      {showMenu && (
        <div onClick={changeShowMenuState} className={Styles.menuCloser}></div>
      )}
      {/* Image Lists */}
      <ImageList
        selectedPage={selectedPage}
        imagesList={imagesList}
        changePage={changePage}
      />
      {/* About */}
      <About changePage={changePage} selectedPage={selectedPage} />
    </div>
  );
};
