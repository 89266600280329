// Modules
import { UserOutlined, TeamOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { motion } from "framer-motion";
import { useState } from "react";
// Styles
import Styles from "./Styles/Header.module.css";
// Icons
import logo from "../../../assets/Icons/logo.png";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("تک نفره", "1", <UserOutlined />, [
    getItem("دختر", "11"),
    getItem("پسر", "12"),
  ]),
  getItem("دو نفره", "2", <TeamOutlined />, [
    getItem("دختر", "21"),
    getItem("بچه", "22"),
    getItem("عاشقانه", "23"),
  ]),
];

const getLevelKeys = (items1) => {
  const key = {};
  const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        return func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const levelKeys = getLevelKeys(items);

export const Header = ({ showMenu, changeShowMenuState, changePage }) => {
  const [stateOpenKeys, setStateOpenKeys] = useState([]);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <motion.div
      initial={{ y: -45 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      className={Styles.container}
    >
      <div className={Styles.logo}>
        <img src={logo} alt="" />
      </div>
      <div className={Styles.menu}>
        <h4 onClick={() => changePage(null)}>خانه</h4>
        <h4 className={Styles.journalDot} onClick={changeShowMenuState}>
          ژورنال
        </h4>
        <h4 onClick={() => changePage("a", "images")}>تبلیغاتی</h4>
        <h4 onClick={() => changePage("n", "images")}>طبیعت</h4>
        <h4 onClick={() => changePage("m", "about")}>درباره من</h4>
        <Menu
          onClick={(e) => changePage(e.key, "images")}
          mode="inline"
          className={`${Styles.journalMenu} ${
            showMenu ? Styles.activeMenu : ""
          }`}
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          style={{
            width: 200,
          }}
          items={items}
        />
      </div>
    </motion.div>
  );
};
