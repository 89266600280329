// Modules
import { motion } from "framer-motion";
// Styles
import Styles from "./Styles/Footer.module.css";

export const Footer = () => {
  return (
    <>
      {/* title */}
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, delay: 1.2 }}
        className={Styles.title}
      >
        SHOTLENZ
      </motion.h1>
      {/* desctiption */}
      <motion.div
        initial={{ y: 200 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className={Styles.descContainer}
      ></motion.div>
      <motion.div
        initial={{ y: 200 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className={Styles.descBackground}
      >
        <h2>به دنیای عکاسی خوش آمدید</h2>
        <p>
          هر عکس یک داستان است، یک داستان که بدون کلمه به زبان عکس ها روایت می
          شود. از شما دعوت میکنم تا همراه من در سفری جذاب به دنیای عکسها
          بپیوندید. با لذت و شوق ، بهترین لحظات زندگی تان را با ما به یادگار
          بسپارید. شما هم میتوانید بخشی از این داستان باشید تا با هم داستان های
          زیبایمان را ادامه دهیم.
        </p>
      </motion.div>
    </>
  );
};
