// Moduls
import { useState } from "react";
// Components
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Menu } from "antd";
// Icons
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import InfoIcon from "@mui/icons-material/Info";
import LandscapeIcon from "@mui/icons-material/Landscape";
import SellIcon from "@mui/icons-material/Sell";
import HomeIcon from "@mui/icons-material/Home";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
// Styles
import Styles from "./Styles/MobileNavigator.module.css";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("تک نفره", "1", <UserOutlined />, [
    getItem("دختر", "11"),
    getItem("پسر", "12"),
  ]),
  getItem("دو نفره", "2", <TeamOutlined />, [
    getItem("دختر", "21"),
    getItem("بچه", "22"),
    getItem("عاشقانه", "23"),
  ]),
];

const getLevelKeys = (items1) => {
  const key = {};
  const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        return func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const levelKeys = getLevelKeys(items);

export const MobileNavigator = ({
  changePage,
  showMenu,
  changeShowMenuState,
}) => {
  const [value, setValue] = useState("home");
  const [stateOpenKeys, setStateOpenKeys] = useState([]);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <BottomNavigation
      sx={{ width: "100%" }}
      value={value}
      onChange={handleChange}
      className={Styles.container}
    >
      <Menu
        onClick={(e) => changePage(e.key, "images")}
        mode="inline"
        className={`${Styles.journalMenu} ${showMenu ? Styles.activeMenu : ""}`}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        style={{
          width: "100%",
        }}
        items={items}
      />
      <BottomNavigationAction
        onClick={() => changePage("n", "images")}
        label="طبیعت"
        value="nature"
        icon={<LandscapeIcon />}
      />
      <BottomNavigationAction
        onClick={() => changePage("a", "images")}
        label="تبلیغاتی"
        value="advertising"
        icon={<SellIcon />}
      />
      <BottomNavigationAction
        onClick={() => changePage(null)}
        label="خانه"
        value="home"
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        onClick={() => changePage("m", "about")}
        label="درباره من"
        value="about"
        icon={<InfoIcon />}
      />
      <BottomNavigationAction
        onClick={changeShowMenuState}
        label="ژورنال"
        value="journal"
        icon={<LocalSeeIcon />}
      />
    </BottomNavigation>
  );
};
