import { useEffect } from "react";
import axios from "axios";

const UserInfo = () => {
  useEffect(() => {
    const { userAgent, language } = navigator;

    const browserInfo = {
      language,
    };

    let detectedDeviceType = "Unknown";
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      detectedDeviceType = "Tablet";
    } else if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)|KFAPWI/i.test(
        userAgent
      )
    ) {
      detectedDeviceType = "Phone";
    } else {
      detectedDeviceType = "Laptop/Desktop";
    }

    browserInfo.deviceType = detectedDeviceType;

    // Send browserInfo object to the server
    axios
      .post("https://shotlenz.ir/users/wellcome", browserInfo)
      .then(() => {
        console.log("خوش آمدید");
      })
      .catch(() => {
        console.error("خوش آمدید!");
      });
  }, []);

  // Component does not render anything
  return null;
};

export default UserInfo;
