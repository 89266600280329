// Modules
import { motion } from "framer-motion";
// Styles
import Styles from "./Styles/Image.module.css";

export const Image = ({
  src,
  positionLeft,
  positionTop,
  triangle,
  animationDuration,
}) => {
  return (
    <motion.img
      initial={{
        rotateZ: triangle ? 0 : -45,
        scale: 0,
        x: triangle ? 100 : -1000,
      }}
      animate={{
        rotateZ: triangle ? 0 : -45,
        scale: 1,
        x: 0,
      }}
      transition={{
        duration: animationDuration * 0.1,
        delay: triangle ? 1 : 0,
      }}
      className={Styles.image}
      style={{
        left: positionLeft + "vw",
        top: positionTop + "vw",
        width: triangle ? "36.2vw" : "12.45vw",
        height: triangle ? "36.2vw" : "12.45vw",
        transform: triangle ? "rotateZ(0)" : "rotateZ(-45deg)",
      }}
      src={src}
      alt=""
    />
  );
};
