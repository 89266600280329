// Modules
import { useState } from "react";
import { Tooltip } from "antd";
// Components
import { ModalImage } from "../../Common/ModalImage/ModalImage";
// Styles
import Styles from "./Styles/ImageList.module.css";
// Icons
import { CloseOutlined, ExpandOutlined } from "@ant-design/icons";

export const ImageList = ({ imagesList, changePage, selectedPage }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const onSelectImage = (id) => {
    setSelectedImage(id);
  };
  return (
    <div
      className={`${Styles.container} ${
        selectedPage?.type === "images" ? Styles.activeList : ""
      }`}
      style={selectedImage ? { zIndex: 1000, height: "100%" } : {}}
    >
      <div className={Styles.fakeNavigator}></div>
      <div className={Styles.imagesContainer}>
        {imagesList
          .filter((i) => i.type === selectedPage?.key)
          .map((item) => (
            <div key={item._id} className={Styles.image}>
              <img src={item.src} alt="" />
              <div className={Styles.mask}>
                <Tooltip placement="top" title="تمام صفحه" color="#00000080">
                  <ExpandOutlined
                    className={Styles.icon}
                    onClick={() => onSelectImage(item._id)}
                  />
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      {selectedImage && (
        <ModalImage
          onSelectImage={onSelectImage}
          imagesList={imagesList}
          selectedImage={selectedImage}
        />
      )}
      <CloseOutlined
        className={Styles.closeIcon}
        onClick={() => changePage(null)}
      />
      <div className={Styles.closeWindow}>
        <svg viewBox="0 0 202.9 45.5">
          <clipPath
            id="closeJournal"
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0049285362247413 0.021978021978022)"
          >
            <path
              d="M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
          c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
          c9.2,3.6,17.6,4.2,23.3,4H6.7z"
            ></path>
          </clipPath>
        </svg>
      </div>
    </div>
  );
};
