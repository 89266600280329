// Styles
import Styles from "./Styles/About.module.css";
// Icons
import { CloseOutlined } from "@ant-design/icons";
import brandIcon from "../../../assets/Icons/single-logo.png";
// Images
import aboutImage from "../../../assets/Images/about.jpg";

export const About = ({ changePage, selectedPage }) => {
  return (
    <div
      className={`${Styles.container} ${
        selectedPage?.type === "about" ? Styles.activeList : ""
      }`}
    >
      <div className={Styles.fakeNavigator}></div>
      <div className={Styles.cover}></div>
      <div className={Styles.content}>
        <div className={Styles.profile}>
          <h2
            className={Styles.brandName}
            style={selectedPage?.type === "about" ? { opacity: 1 } : {}}
          >
            shotlenz
          </h2>
          <img
            style={selectedPage?.type === "about" ? { opacity: 1 } : {}}
            className={Styles.image}
            src={aboutImage}
            alt=""
          />
          <h3
            style={selectedPage?.type === "about" ? { opacity: 1 } : {}}
            className={Styles.name}
          >
            narges jokar
          </h3>
          <img
            style={selectedPage?.type === "about" ? { opacity: 1 } : {}}
            className={Styles.nameLogo}
            src={brandIcon}
            alt=""
          />
          <h3
            style={selectedPage?.type === "about" ? { opacity: 1 } : {}}
            className={Styles.instagramAdress}
          >
            shotlenz.ir
          </h3>
          <img
            style={selectedPage?.type === "about" ? { opacity: 1 } : {}}
            className={Styles.instagramLogo}
            src={brandIcon}
            alt=""
          />
        </div>
        <div className={Styles.description}>
          <div className={Styles.descSection}>
            <h4
              style={
                selectedPage?.type === "about"
                  ? { opacity: 1, transitionDelay: "0.9s" }
                  : { transitionDelay: "0.9s" }
              }
              className={Styles.title}
            >
              عکاسی پرتره
            </h4>
            <p
              style={
                selectedPage?.type === "about"
                  ? { opacity: 1, transitionDelay: "1.4s" }
                  : {}
              }
              className={Styles.desc}
            >
              زیبایی عکس های تولد به لوکیشن و ژست شماست خدمات تولد درفضای باز و
              منزل و طبیعت دست شما را در انتخاب لوکیشن عکاسی باز میگذارد .
              متفاوت ترین عکس های تولد را در قاب شات لنز ثبت کنید.
            </p>
          </div>
          <div className={Styles.descSection}>
            <h4
              style={
                selectedPage?.type === "about"
                  ? { opacity: 1, transitionDelay: "1.9s" }
                  : { transitionDelay: "1.9s" }
              }
              className={Styles.title}
            >
              عکاسی تبلیغاتی
            </h4>
            <p
              style={
                selectedPage?.type === "about"
                  ? { opacity: 1, transitionDelay: "2.4s" }
                  : { transitionDelay: "2.4s" }
              }
              className={Styles.desc}
            >
              دنیای امروز دنیای رقابت است. مشتریان به دنبال شرکتی هستند که
              متفاوت از دیگران محصولات و خدمات خود را معرفی کند. خدمات 360 درجه
              عکاسی تبلیغاتی شات لنز به شما کمک میکند تا متمایز باشید.
            </p>
          </div>
          <div className={Styles.descSection}>
            <h4
              style={
                selectedPage?.type === "about"
                  ? { opacity: 1, transitionDelay: "2.9s" }
                  : { transitionDelay: "2.9s" }
              }
              className={Styles.title}
            >
              عکاسی تولد و دورهمی
            </h4>
            <p
              style={
                selectedPage?.type === "about"
                  ? { opacity: 1, transitionDelay: "3.4s" }
                  : { transitionDelay: "3.4s" }
              }
              className={Styles.desc}
            >
              زیبایی عکس های تولد به لوکیشن و ژست شماست خدمات تولد درفضای باز و
              منزل و طبیعت دست شما را در انتخاب لوکیشن عکاسی باز میگذارد .
              متفاوت ترین عکس های تولد را در قاب شات لنز ثبت کنید.
            </p>
          </div>
        </div>
      </div>
      <CloseOutlined
        className={Styles.closeIcon}
        onClick={() => changePage(null)}
      />
      <div className={Styles.closeWindow}>
        <svg viewBox="0 0 202.9 45.5">
          <clipPath
            id="closeAbout"
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0049285362247413 0.021978021978022)"
          >
            <path
              d="M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
      c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
      c9.2,3.6,17.6,4.2,23.3,4H6.7z"
            ></path>
          </clipPath>
        </svg>
      </div>
    </div>
  );
};
