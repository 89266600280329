// Modules
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
// Styles
import Styles from "./Styles/ModalImage.module.css";
// Icons
import { CloseOutlined } from "@ant-design/icons";

export const ModalImage = ({ imagesList, selectedImage, onSelectImage }) => {
  const [src, setSrc] = useState();
  useEffect(() => {
    setSrc(imagesList.find((i) => i._id === selectedImage).src);
  }, []);
  return (
    <motion.div
      initial={{ y: 1000 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.4 }}
      className={Styles.container}
    >
      <div className={Styles.navbar}>
        <CloseOutlined
          className={Styles.icon}
          onClick={() => onSelectImage(null)}
        />
      </div>
      {selectedImage && <img className={Styles.image} src={src} alt="" />}
    </motion.div>
  );
};
